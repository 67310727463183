import React from "react"
import { Layout, Breadcrumb, Col, Row, Menu, Dropdown, Modal, Form, Button, message, Input, Divider } from "antd"
import Siderbar from "./sider"
import { CloudFilled, DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from "@ant-design/icons"
import config from "../../config/config"
import jwt_decode from "jwt-decode"
import { Redirect } from "react-router-dom"
import { useAuth } from "../../provider/auth"
import { useMenu } from "../../provider/menu"
import './template.less'

const { Header, Content, Footer } = Layout
const Template = (props) => {
    const [logout, setLogout] = React.useState(false)
    const { user, setUser } = useAuth()
    const { menub, setMenu } = useMenu();
    const [form] = Form.useForm()
    const [isModalUpdate, setIsModalUpdate] = React.useState(false)

    // const { user, setUser } = useAuth()

    const sairButton = () => {
        setLogout(true)
        localStorage.removeItem("authUserOptica")
    }

    const loadHeader = () => {
        let { usuario } = jwt_decode(localStorage.getItem("authUserOptica"))

        if (user === undefined) {
            console.log(user)
            setUser(usuario)
        }
    }

    React.useEffect(() => {
        loadHeader()
        console.log(user)
    }, [user])

    const openUsuario = async () => {
        console.log(user)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(
            config[process.env.NODE_ENV].url + "/usuarios/edit/" + user.id,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
            }
        )
            .then((response) => {
                return response.json()
            }).then((response) => {
                setIsModalUpdate(true)
                let dados = response.content[0]
                delete dados.senha

                if (dados.localidade != undefined) {
                    form.setFieldsValue({
                        'nome': dados.cooperador,
                        'email': dados.login,
                        'funcao': 'ADM'

                    })
                } else {
                    form.setFieldsValue(dados)
                }

            })

    }

    const onFinishUpdate = async (values) => {
        const _initToken = await localStorage.getItem("authUserOptica")
        const idUser = values.id != undefined ? values.id : user.id
        fetch(
            config[process.env.NODE_ENV].url + "/usuarios/edit/" + idUser,
            {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
                body: JSON.stringify(values),
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    // setUsuarios(data.content)
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {

                        })
                }
            })
            .catch((err) => {
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const menu = (
        <Menu>

            <Menu.Item key="0" onClick={() => openUsuario()}>
                Perfil
            </Menu.Item>

            <Menu.Item key="1" onClick={() => sairButton()}>
                Sair
            </Menu.Item>
        </Menu>
    )

    return (
        <Layout>
            {logout == true && <Redirect to="/" />}
            <Siderbar />

            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{ padding: 0, marginBottom: "50px" }}
                >
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="button">
                                {React.createElement(
                                    menub.collapsed
                                        ? MenuUnfoldOutlined
                                        : MenuFoldOutlined,
                                    {
                                        className: "trigger",
                                        onClick: () => {
                                            setMenu({
                                                collapsed: !menub.collapsed,
                                            });
                                        },
                                    }
                                )}
                            </div>
                        </Col>
                        <Col
                            span={12}
                            style={{ textAlign: "right", paddingRight: "20px" }}
                        >
                            <Dropdown overlay={menu}>
                                <a
                                    className="ant-dropdown-link"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <UserOutlined />{" "}
                                    {user != "" ? user?.nome : ""}
                                </a>
                            </Dropdown>
                        </Col>
                    </Row>
                </Header>

                <Content style={{ padding: "0 50px" }}>
                    {/* <Content style={{ margin: "24px 16px 0", overflow: "initial" }}> */}

                    <div
                        className="site-layout-background"
                        style={{ padding: 24, textAlign: "center" }}
                    >
                        {props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: "center" }}>Dourados ©2021</Footer>
            </Layout>

            <Modal
                footer={false}
                destroyOnClose={true}
                title="Editar usuário"
                visible={isModalUpdate}
                onCancel={() => setIsModalUpdate(false)}
                onFinish={() => setIsModalUpdate(false)}


            >
                <Form form={form} layout={"vertical"} onFinish={onFinishUpdate}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="Nome Completo"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Nome obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "E-mail não é válido!",
                                    },
                                    {
                                        required: true,
                                        message: "Campo senha obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Cargo"
                                name="funcao"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="senha"
                                name="senha"
                                rules={[
                                    {
                                        required: false,
                                        message: "Campo Senha obrigatório!",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Repita a senha"
                                name="confirm"
                                dependencies={["senha"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: false,
                                        message:
                                            "Please confirm your password!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue("senha") === value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "As duas senhas que você digitou não coincidem!!"
                                                )
                                            )
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="repeat">
                                <Button htmlType="submit">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </Layout>
    )
}

export default Template
