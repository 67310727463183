import React, { useEffect } from "react"
import Template from "../../components/template"
import NumberFormat from "react-number-format"
import InputMoney from "../../components/inputMoneyMask"
import config from "../../config/config"
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    PageHeader,
    message,
    Radio,
    Select,
    Divider,
    DatePicker,
} from "antd"
import { Redirect } from "react-router-dom"
import {
    DownloadOutlined,
    SaveOutlined,
    UserAddOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from "@ant-design/icons"
import moment from "moment"
import "moment/locale/pt"
import locales from "antd/es/date-picker/locale/pt_PT"

const locale = "en-us"

const Seguro = () => {
    const [form] = Form.useForm()
    const [redirect, setRedirect] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [registro, setRegistro] = React.useState(2)
    const [premio, setPremio] = React.useState(0)
    const [clientes, setClientes] = React.useState([])
    const [usuario, setUsuario] = React.useState([])
    const { Option } = Select

    const { TextArea } = Input

    const loadCliente = async () => {
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/clientes/loja", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                    setLoading(false)
                } else {
                    setClientes(data.content)
                    // message
                    //     // .success("Dados enviado com sucesso!", 3)
                    //     // .then(() => {
                    //     //     // setLoading(false)
                    //     //     // setRedirect(true)
                    //     // })
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                message.error("Servidor está fora")
            })
    }

    const fetchUsuario = async () => {
        const _initToken = await localStorage.getItem("authUserOptica")
        let response = await fetch(
            config[process.env.NODE_ENV].url + "/usuarios",
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
            }
        )
        let result = await response.json()
        setUsuario(result.content)
    }
    const onFinish = async (values) => {
        setLoading(true)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/seguro/store", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                    setLoading(false)
                } else {
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            setRedirect(true)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                message.error("Servidor está fora")
            })
    }
    const onReset = () => {
        form.resetFields()
    }

    const onChangeType = (e) => {
        setRegistro(e.target.value)
    }

    const regraSeguro = (e) => {
        let valor = parseFloat(
            e.target.value.replace("€", "").replaceAll(",", "")
        )

        console.log(valor)

        if (isNaN(valor)) {
            form.setFieldsValue({ valor_sgr: 0 })
        }

        if (valor == 0) {
            form.setFieldsValue({ valor_sgr: 0 })
        }

        if (valor > 0 && valor <= 200) {
            form.setFieldsValue({ valor_sgr: 20 })
        }

        if (valor >= 200 && valor <= 299) {
            form.setFieldsValue({ valor_sgr: 30 })
        }

        if (valor >= 300 && valor <= 499) {
            form.setFieldsValue({ valor_sgr: 45 })
        }

        if (valor >= 500 && valor <= 999) {
            form.setFieldsValue({ valor_sgr: 75 })
        }

        if (valor >= 1000 && valor <= 1499) {
            form.setFieldsValue({ valor_sgr: 95 })
        }

        if (valor >= 1500 && valor <= 2000) {
            form.setFieldsValue({ valor_sgr: 100 })
        }

        if (valor > 2000) {
            form.setFieldsValue({ valor_sgr: 100 })
        }

        onChangeComissao()
    }

    const onChangeComissao = () => {
        // calcular comissao de 12,5%
        const valorSeguro = form.getFieldValue("valor_sgr")

        console.log(valorSeguro)

        let comissao = valorSeguro * 0.125
        form.setFieldsValue({ valor_cms: comissao })
    }

    const currencyFormatter = (selectedCurrOpt) => (value) => {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: selectedCurrOpt.split("::")[1],
        }).format(value)
    }

    useEffect(() => {
        fetchUsuario()
        loadCliente()
    }, [])

    return (
        <Template>
            {redirect && <Redirect to="/gerenciar" />}
            <PageHeader
                className="site-page-header"
                title="Novo Seguro"
                // breadcrumb={{ routes }}
                subTitle="formulário"
                style={{ paddingLeft: "0%" }}
            />

            <Row>
                <Col>
                    <Radio.Group onChange={onChangeType}>
                        <Radio.Button value="1">
                            <UserOutlined /> JÁ POSSUI REGISTO
                        </Radio.Button>
                        <Radio.Button value="2">
                            <UserAddOutlined /> NOVO REGISTO
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <br />

            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={20}>
                    {registro == 2 ? (
                        <Col span={12}>
                            <Row gutter={20}>
                                <Col span={24}>
                                    <Divider orientation="left">
                                        Dados do cliente
                                    </Divider>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="nome"
                                        label="Nome"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Campo nome obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="nif"
                                        label="NIF"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Campo NIF obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="telefone"
                                        label="Telefone"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Campo Telefone obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="email"
                                        label="E-mail"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Campo E-mail obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="codpostal"
                                        label="Cód. Postal"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="morada"
                                        label="Morada"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="localidade"
                                        label="Localidade"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    ) : (
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="left">
                                        Dados Cliente
                                    </Divider>
                                </Col>
                            </Row>

                            <Form.Item name="cliente_id" label="Clientes">
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children
                                            .toLowerCase()
                                            .localeCompare(
                                                optionB.children.toLowerCase()
                                            )
                                    }
                                >
                                    {clientes.map((v) => (
                                        <Option value={v.id}>{v.nome}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Divider orientation="left">
                                    Dados do seguro
                                </Divider>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="numero_fatura"
                                    label="Número da Fatura Associada
"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Campo Número de Fatura Associada obrigatório",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="modelo_dsc"
                                    label="Modelo / Descrição do Produto Segurado"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Modelo obrigatório",
                                        },
                                    ]}
                                >
                                    <TextArea />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="valor_prd"
                                    label="Valor do Produto"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Campo Valor do Produto obrigatório",
                                        },
                                    ]}
                                >
                                    <InputMoney
                                        form={form}
                                        onBlur={(e) => regraSeguro(e)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="valor_sgr"
                                    label="Valor do Seguro"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Campo Valor do Seguro obrigatório",
                                        },
                                    ]}
                                >
                                    <InputMoney form={form} disabled />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    name="valor_cms"
                                    label="Valor da Comissão"
                                >
                                    <InputMoney form={form} disabled />
                                </Form.Item>
                            </Col>

                            <Col span={12} style={{ textAlign: "left" }}>
                                <Form.Item
                                    name="data_registro"
                                    label="Data da venda"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo data é obrigatório",
                                        },
                                    ]}
                                >
                                    <DatePicker locale={locales} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="usuario_id"
                                    label="Vendedor"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Campo Vendedor é obrigatório",
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        showSearch
                                        filterOption={(inputValue, option) =>
                                            option.children
                                                .join("")
                                                .toLowerCase()
                                                .includes(
                                                    inputValue.toLowerCase()
                                                )
                                        }
                                        // filterSort={(optionA, optionB) =>
                                        //     optionA.children
                                        //         .toLowerCase()
                                        //         .localeCompare(
                                        //             optionB.children.toLowerCase()
                                        //         )
                                        // }
                                    >
                                        {usuario.map((v) => (
                                            <Option value={v.id}>
                                                {v.nome.toUpperCase()} - (
                                                {v.funcao.toUpperCase()})
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={20}></Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item style={{ textAlign: "left" }}>
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                htmlType="submit"
                                loading={loading}
                            >
                                Salvar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Template>
    )
}

export default Seguro
