import React from "react"
import Template from "../../components/template"
import {Col, Row, Form, Input, PageHeader, Button, message} from  'antd'

import config from "../../config/config"
const Contato = () => {
    const [form] = Form.useForm();
    const { TextArea} = Input
    const [loading, setLoading] = React.useState(false)

    const onFinish = async(values) =>{
            setLoading(true)
         const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/contato/email", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    setLoading(false)
                    message.error(data.message)

                } else {
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false)


                        })
                }
            })
            .catch((err) => {
                console.log(err)
                message.error("Servidor está fora")
                setLoading(false)
            })
    }



    return (
        <Template>
         <PageHeader
                className="site-page-header"
                title="Contato"
                // breadcrumb={{ routes }}
                subTitle=""
                style={{ paddingLeft: "0%" }}
            />
        <Row>

            <Col span={12}>
                 <Form layout="vertical" onFinish={onFinish}>
                <Row gutter={[20,20]}>
                    <Col span={24}>
                        <Form.Item
                        name="nome"
                        label="Nome Completo"
                                rules={[{ required: true, message: 'Campo nome obrigatório!' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                     <Col span={24}>
                        <Form.Item
                        label="Assunto"
                        name="assunto"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                      <Col span={24}>
                        <Form.Item
                        label="Mensagem"
                        name="mensagem"
                        >
                            <TextArea  showCount maxLength={300} />
                        </Form.Item>
                    </Col>

                      <Col span={24} style={{textAlign: 'right'}}>
                        <Form.Item >
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}>
                                Enviar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            </Col>

        </Row>

        </Template>
    )
}

export default Contato
