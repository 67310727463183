import React, { useEffect, useState, useForm } from "react"
import Template from "../../components/template"
import config from "../../config/config"
import moment from "moment"
import { saveAs } from "file-saver"
import {
    PageHeader,
    Table,
    message,
    Button,
    Modal,
    Input,
    Form,
    Upload,
    Tag,
    Row,
    Col,
    Switch,
    Space,
} from "antd"
import { maskCurrency } from "../../components/utils"
import {
    DownloadOutlined,
    UploadOutlined,
    StarOutlined,
} from "@ant-design/icons"
import { InputSearch } from "../../components/inputSearch"
const GerenciamentSeguro = () => {
    const [seguros, setSeguros] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [seguro, setSeguro] = useState([])
    const [loading, setLoading] = useState(false)
    const [loaDownload, setLoaDownload] = useState(false)
    const [modalDownload, setModalDownload] = useState(false)
    const [modalUpload, setModalUpload] = useState(false)
    const _initToken = localStorage.getItem("authUserOptica")
    const [form] = Form.useForm()

    const onConfirmPag = (index, status) => {
        let data = {
            id: seguros[index].id,
            pag_sgr: status,
        }

        fetch(config[process.env.NODE_ENV].url + "/seguro/pagamento", {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    message.success("Dados atualizados com sucesso")
                    loadDataSeguros()
                }
            })
            .catch((err) => {
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
            sorter: (a, b) => a.nome.length - b.nome.length,
            sortDirections: ["descend"],
        },
        // {
        //     title: "NIF",
        //     dataIndex: "nif",
        //     key: "nif",
        //       sorter: (a, b) => a.nif - b.nif,
        // },
        // {
        //     title: "E-mail",
        //     dataIndex: "email",
        //     key: "email",
        // },

        // {
        //     title: "Telefone",
        //     dataIndex: "telefone",
        //     key: "telefone",
        // },

        {
            title: "Número da Fatura",
            dataIndex: "numero_fatura",
            key: "numero_fatura",
            sorter: (a, b) => a.numero_fatura - b.numero_fatura,
        },

        {
            title: "Valor do produto",
            dataIndex: "valor_prd",
            key: "valor_prd",
            sorter: (a, b) => a.valor_prd - b.valor_prd,
            render: (valor) => {
                return maskCurrency(valor)
            },
        },

        {
            title: "Valor do seguro",
            dataIndex: "valor_sgr",
            key: "valor_sgr",
            sorter: (a, b) => a.valor_sgr - b.valor_sgr,
            render: (valor) => {
                return maskCurrency(valor)
            },
        },
        {
            title: "Data de Validade",
            dataIndex: "data_exp",
            key: "data_exp",
            sorter: (a, b) => a.data_exp - b.data_exp,
            render: (value) => {
                return moment(value).format("DD/MM/YYYY")
            },
        },

        {
            title: "Data de Registo",
            dataIndex: "created_at",
            key: "created_at",
            sorter: (a, b) => a.created_at - b.created_at,
            render: (value) => {
                return moment(value).format("DD/MM/YYYY")
            },
        },

        // {
        //     title: "Status Comprovativos",
        //     dataIndex: "status_comprovativos",
        //     key: "status_comprocativos",
        //     filters: [
        //         {
        //             text: "Aguardando",
        //             value: 1,
        //         },
        //         {
        //             text: "Aprovado",
        //             value: 2,
        //         },
        //         {
        //             text: "Reprovado",
        //             value: 3,
        //         },
        //     ],
        //     onFilter: (value, record) => {
        //         console.log(record, value)
        //         return record.status_comprovativos == value
        //     },
        //     render: (v) => {
        //         switch (v) {
        //             case 1:
        //                 return <Tag color="orange">Aguardando</Tag>
        //                 break
        //             case 2:
        //                 return <Tag color="green">Aprovado</Tag>
        //                 break
        //             case 3:
        //                 return <Tag color="red">Reprovado</Tag>
        //                 break
        //         }
        //     },
        // },
        {
            title: "Pagamento Seguro",
            dataIndex: "pag_sgr",
            key: "pag_sgr",
            render: (status, record, index) => {
                const onToggle = (checked) => {
                    status = checked
                    onConfirmPag(index, status)
                }
                return (
                    <Space>
                        <Switch defaultChecked={status} onChange={onToggle} />
                    </Space>
                )
            },
        },
        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            render: (value, record) => {
                return (
                    <>
                        <Button
                            size="small"
                            icon={<DownloadOutlined />}
                            style={{ marginRight: "10px" }}
                            onClick={() => showModalDownload(record)}
                        >
                            Certificado
                        </Button>

                        <Button
                            size="small"
                            icon={<UploadOutlined />}
                            onClick={() => DownloadRGPD()}
                        >
                            RGPD
                        </Button>
                    </>
                )
            },
        },
    ]

    // Show modal Upload Certificado
    const showModalUpload = (record) => {
        setModalUpload(true)
        setSeguro(record)
        console.log(seguro)
    }

    const showModalDownload = (record) => {
        console.log(record)
        setModalDownload(true)
        setSeguro(record)
    }

    const createAndDownloadPdf = (dados) => {
        fetch(config[process.env.NODE_ENV].url + "/seguro/pdf", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(dados),
        }).then(() => {
            fetch(config[process.env.NODE_ENV].url + "/seguro/fetch-pdf", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
                body: JSON.stringify(dados),
            }).then((res) => {
                if (res.ok) {
                    return res.blob().then((myBlob) => {
                        saveAs(myBlob, "generatedDocument.pdf")
                    })
                }
            })
        })
    }

    const loadDataSeguros = async () => {
        setLoading(true)
        fetch(config[process.env.NODE_ENV].url + "/seguro", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    setSeguros(data.content)
                    setDataTable(data.content)
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const DownloadRGPD = async () => {
        fetch(config[process.env.NODE_ENV].url + "/seguro/fetch-rgpd", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        }).then((res) => {
            if (res.ok) {
                return res.blob().then((myBlob) => {
                    saveAs(myBlob, "rgpd.pdf")
                })
            }
        })
    }

    const uploadSeguroSend = async (values) => {
        let data = new FormData()
        data.append("arquivo_rgpd", values)
        data.append("id_seguro", seguro.id)

        console.log(data)
        fetch(config[process.env.NODE_ENV].url + "/seguro/upload-rgpd", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: data,
        })
    }

    const handleSearch = (text) => {
        const filtered = dataTable.filter(({ nome }) => {
            nome = nome.toLowerCase()
            return nome.includes(text)
        })

        setSeguros(filtered)
    }

    useEffect(() => {
        loadDataSeguros()
    }, [])

    return (
        <Template>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <PageHeader
                        className="site-page-header"
                        title="Gerenciar"
                        // breadcrumb={{ routes }}
                        subTitle="seguros"
                        style={{ paddingLeft: "0%" }}
                    />
                </Col>
                <Col span={24} style={{ textAlign: "left" }}>
                    <InputSearch
                        onSearch={handleSearch}
                        placeholder="Nome Cliente"
                    />
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={seguros}
                        columns={columns}
                        size="small"
                        bordered
                        loading={loading}
                    />
                </Col>
                {/* MODAL CERTIFICADO */}
                <Modal
                    title="Certificado Seguro"
                    visible={modalDownload}
                    destroyOnClose={true}
                    footer={false}
                    onOk={() => setModalDownload(false)}
                    onCancel={() => setModalDownload(false)}
                >
                    <p style={{ textAlign: "center" }}>
                        Download do Certificado do Seguro{" "}
                        <Button
                            icon={<DownloadOutlined />}
                            onClick={() => createAndDownloadPdf(seguro)}
                        >
                            Download
                        </Button>
                    </p>
                    <p>
                        {/* Enviar por E-mail{" "} */}
                        {/* <Form layout="inline" form={form}>
                        <Form.Item>
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" loading>
                                Enviar
                            </Button>
                        </Form.Item>
                    </Form> */}
                    </p>
                </Modal>
                <Modal
                    visible={modalUpload}
                    title="Certificado RGPD"
                    onCancel={() => setModalUpload(false)}
                    onOk={() => setModalUpload(false)}
                    footer={false}
                    destroyOnClose={true}
                >
                    <p>Upload Certificado RGPD: # {seguro.id}</p>
                    <Form>
                        <Form.Item name="arquivo_rgpd">
                            <p style={{ textAlign: "center" }}>
                                Download RGPD{" "}
                                <Button
                                    icon={<DownloadOutlined />}
                                    onClick={() => DownloadRGPD()}
                                >
                                    Donwload
                                </Button>
                            </p>
                            {/* <Upload
                            name="logo"
                            listType="picture"
                            action={uploadSeguroSend}
                            maxCount={1}
                            progress={{
                                strokeColor: {
                                    "0%": "#108ee9",
                                    "100%": "#87d068",
                                },
                                strokeWidth: 3,
                                format: (percent) =>
                                    `${parseFloat(percent.toFixed(2))}%`,
                            }}
                            defaultFileList={[
                                {
                                    uid: "1",
                                    name: seguro.arquivo_rgpd,
                                    status: "done",
                                    response: "Server Error 500", // custom error message to show
                                    url:
                                        config[process.env.NODE_ENV].url +
                                        "/seguro/download/" +
                                        seguro.arquivo_rgpd,
                                },
                            ]}
                            showUploadList={{
                                showDownloadIcon: false,
                                downloadIcon: "download",
                            }}
                        >
                            <Button icon={<UploadOutlined />}>
                                Click para upload
                            </Button>
                        </Upload> */}
                        </Form.Item>
                    </Form>
                </Modal>
            </Row>
        </Template>
    )
}

export default GerenciamentSeguro
