import React from "react"
import Template from "../../components/template"
import {
    PageHeader,
    Row,
    Col,
    Button,
    Table,
    message,
    Modal,
    Form,
    Input,
    Checkbox,
    Upload,
    Select,
    Tag,
} from "antd"
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SearchOutlined,
} from "@ant-design/icons"
import moment from "moment"
import config from "../../config/config"
import Highlighter from "react-highlight-words"
const Clientes = () => {
    const [loading, setLoading] = React.useState(false)
    const [submit, setSubmit] = React.useState(false)
    const [isModal, setIsModal] = React.useState(false)
    const [isModalUpdate, setIsModalUpdate] = React.useState(false)
    const [usuarios, setUsuarios] = React.useState([])
    const [usuario, setUsuario] = React.useState({})
    const [statusSelect, setStatusSelect] = React.useState("")
    const { confirm } = Modal
    const { Option } = Select

    const [searchText, setSearchText] = React.useState("");
    const [searchedColumn, setSearchedColumn] = React.useState("");
    const searchInput = React.useRef();

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Busca`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="mini"
                    onClick={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Procurar
                </Button>
                <Button
                    type="mini"
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text) => {
            return searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            );
        },
    });
    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
             sorter: (a, b) => a.id.length - b.id.length,
        },
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
             ...getColumnSearchProps("nome"),
            sorter: (a, b) => a.nome.length - b.nome.length,

        },
        {
            title: "E-mail/Login",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.length - b.email.length,
             ...getColumnSearchProps("email"),
        },
         {
            title: "Telefone",
            dataIndex: "telefone",
            key: "telefone",
        },
        {
            title: "Localidade",
            dataIndex: "localidade",
            key: "localidade",
            sorter: (a, b) => a.localidade.length - b.localidade.length,
            ...getColumnSearchProps("localidade"),
        },
        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            render: (value, record) => {
                return (
                    <>
                        <Button
                            size="small"
                            icon={<SearchOutlined />}
                            style={{ marginRight: "10px" }}
                            onClick={() => modalUpdateUsuario(record)}
                        >
                            Visualizar
                        </Button>

                        {/* <Button
                            size="small"
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => {
                                return confirm({
                                    icon: <ExclamationCircleOutlined />,
                                    content:
                                        "Tem certeza que você deseja excluir este registro?",
                                    onOk() {
                                        hadleDelete(record.id)
                                    },
                                    onCancel() {
                                        console.log("Cancel")
                                    },
                                })
                            }}
                        >
                            Deletar
                        </Button> */}
                    </>
                )
            },
        },
    ]

    const showModal = () => {
        setIsModal(true)
    }

    const showModalUpdate = (values) => {
        setIsModalUpdate(true)
    }
    const closeModal = () => {
        setIsModal(false)
    }

    const hadleDelete = async (id) => {
        setLoading(true)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/usuarios/remover/" + id, {
            method: "DELETE",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.content) {
                    message
                        .success("Usuário removido com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            loadClientes()
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                message.error("Servidor está fora")
                setLoading(false)
            })
    }

    const onFinish = async (values) => {
        setSubmit(true)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/usuarios/store", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    // setUsuarios(data.content)
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            loadClientes()
                            setSubmit(false)
                            setIsModal(false)
                        })
                }
            })
            .catch((err) => {
                setLoading(false)
                setSubmit(false)
                console.log(err)
                message.error("Servidor está fora")
            })

        setInterval(() => {
            setSubmit(false)
        }, 3000)
    }

    const onFinishUpdate = async (values) => {
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(
            config[process.env.NODE_ENV].url + "/usuarios/update/" + values.id,
            {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
                body: JSON.stringify(values),
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    // setUsuarios(data.content)
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            loadClientes()
                            setSubmit(false)
                            setIsModal(false)
                        })
                }
            })
            .catch((err) => {
                setLoading(false)
                setSubmit(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const loadClientes = async () => {
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/clientes/loja", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    setUsuarios(data.content)
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const modalUpdateUsuario = (values) => {
        delete values.senha

        setIsModalUpdate(true)

        // values.status = values.status == 1 ? "Ativo" : "Inativo"
        // console.log(values)
        setStatusSelect(values.status == 1 ? "Ativo" : "Inativo")

        form2.setFieldsValue(values)
    }



    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    React.useEffect(() => {
        loadClientes()
    }, [])
    return (
        <Template>
            <PageHeader
                className="site-page-header"
                title="Clientes"
                // breadcrumb={{ routes }}
                subTitle="gerenciamento"
                style={{ paddingLeft: "0%" }}
            />

            <Row gutter={[20, 20]}>

                <Col span={24}>
                    <Table
                        size="small"
                        dataSource={usuarios}
                        columns={columns}
                        loading={loading}
                    />
                </Col>
            </Row>

            <Modal
                visible={isModal}
                footer={false}
                onCancel={closeModal}
                destroyOnClose={true}
                title="Registro de usuário"
            >
                <Form form={form} layout={"vertical"} onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="Nome Completo"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Nome obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "E-mail não é válido!",
                                    },
                                    {
                                        required: true,
                                        message: "Campo senha obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Telefone"
                                name="telefone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>





                        <Col span={24}>
                            <Form.Item name="repeat">
                                <Button loading={submit} htmlType="submit">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                footer={false}
                destroyOnClose={true}
                title="Cliente"
                visible={isModalUpdate}
                onCancel={() => setIsModalUpdate(false)}
                onFinish={() => setIsModalUpdate(false)}
            >
                <Form
                    form={form2}
                    layout={"vertical"}
                    onFinish={onFinishUpdate}
                >
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="NIF"
                                name="nif"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Nome obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Nome Completo"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Nome obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "E-mail não é válido!",
                                    },
                                    {
                                        required: true,
                                        message: "Campo senha obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Telefone"
                                name="telefone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                            <Col span={24}>
                            <Form.Item
                                label="Morada"
                                name="morada"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Localidade"
                                name="localidade"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Cód Postal"
                                name="codpostal"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>




                        {/* <Col span={24}>
                            <Form.Item name="repeat">
                                <Button loading={submit} htmlType="submit">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col> */}
                    </Row>
                </Form>
            </Modal>
        </Template>
    )
}
export default Clientes
