import React from "react"
import Template from "../../components/template"
import {
    PageHeader,
    Row,
    Col,
    Button,
    Table,
    message,
    Modal,
    Form,
    Input,
    Checkbox,
    Upload,
    Select,
    Tag,
} from "antd"
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons"
import moment from "moment"
import config from "../../config/config"
const Usuarios = () => {
    const [loading, setLoading] = React.useState(false)
    const [submit, setSubmit] = React.useState(false)
    const [isModal, setIsModal] = React.useState(false)
    const [isModalUpdate, setIsModalUpdate] = React.useState(false)
    const [usuarios, setUsuarios] = React.useState([])
    const [usuario, setUsuario] = React.useState({})
    const [statusSelect, setStatusSelect] = React.useState("")
    const { confirm } = Modal
    const { Option } = Select

    const [form] = Form.useForm()
    const [form2] = Form.useForm()

    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
        },
        {
            title: "E-mail/Login",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Função",
            dataIndex: "funcao",
            key: "funcao",
        },

        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (v) => {
                switch (v) {
                    case 1:
                        return <Tag color="orange">Ativo</Tag>
                        break
                    case 2:
                        return <Tag color="green">Desativado</Tag>
                        break
                }
            },
        },
        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            render: (value, record) => {
                return (
                    <>
                        <Button
                            size="small"
                            icon={<EditOutlined />}
                            style={{ marginRight: "10px" }}
                            onClick={() => modalUpdateUsuario(record)}
                        >
                            Editar
                        </Button>

                        <Button
                            size="small"
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => {
                                return confirm({
                                    icon: <ExclamationCircleOutlined />,
                                    content:
                                        "Tem certeza que você deseja excluir este registro?",
                                    onOk() {
                                        hadleDelete(record.id)
                                    },
                                    onCancel() {
                                        console.log("Cancel")
                                    },
                                })
                            }}
                        >
                            Deletar
                        </Button>
                    </>
                )
            },
        },
    ]

    const showModal = () => {
        setIsModal(true)
    }

    const showModalUpdate = (values) => {
        setIsModalUpdate(true)
    }
    const closeModal = () => {
        setIsModal(false)
    }

    const hadleDelete = async (id) => {
        setLoading(true)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/usuarios/remover/" + id, {
            method: "DELETE",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.content) {
                    message
                        .success("Usuário removido com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            loadUsuarios()
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                message.error("Servidor está fora")
                setLoading(false)
            })
    }

    const onFinish = async (values) => {
        setSubmit(true)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/usuarios/store", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    // setUsuarios(data.content)
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            loadUsuarios()
                            setSubmit(false)
                            setIsModal(false)
                        })
                }
            })
            .catch((err) => {
                setLoading(false)
                setSubmit(false)
                console.log(err)
                message.error("Servidor está fora")
            })

        setInterval(() => {
            setSubmit(false)
        }, 3000)
    }

    const onFinishUpdate = async (values) => {
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(
            config[process.env.NODE_ENV].url + "/usuarios/edit/" + values.id,
            {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
                body: JSON.stringify(values),
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    // setUsuarios(data.content)
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            loadUsuarios()
                            setSubmit(false)
                            setIsModal(false)
                        })
                }
            })
            .catch((err) => {
                setLoading(false)
                setSubmit(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const loadUsuarios = async () => {
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/usuarios", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    setUsuarios(data.content)
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const modalUpdateUsuario = (values) => {
        delete values.senha

        setIsModalUpdate(true)

        // values.status = values.status == 1 ? "Ativo" : "Inativo"
        // console.log(values)
        setStatusSelect(values.status == 1 ? "Ativo" : "Inativo")

        form2.setFieldsValue(values)
    }

    React.useEffect(() => {
        loadUsuarios()
    }, [])
    return (
        <Template>
            <PageHeader
                className="site-page-header"
                title="Usuários"
                // breadcrumb={{ routes }}
                subTitle="gerenciamento"
                style={{ paddingLeft: "0%" }}
            />

            <Row gutter={[20, 20]}>
                <Col span={24} style={{ textAlign: "left" }}>
                    <Button icon={<PlusOutlined />} onClick={showModal}>
                        Adicionar
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        size="small"
                        dataSource={usuarios}
                        columns={columns}
                        loading={loading}
                    />
                </Col>
            </Row>

            <Modal
                visible={isModal}
                footer={false}
                onCancel={closeModal}
                destroyOnClose={true}
                title="Registro de usuário"
            >
                <Form form={form} layout={"vertical"} onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="Nome Completo"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Nome obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "E-mail não é válido!",
                                    },
                                    {
                                        required: true,
                                        message: "Campo senha obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Cargo"
                                name="funcao"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="senha"
                                name="senha"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Senha obrigatório!",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Repita a senha"
                                name="confirm"
                                dependencies={["senha"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please confirm your password!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue("senha") === value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "As duas senhas que você digitou não coincidem!!"
                                                )
                                            )
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="repeat">
                                <Button loading={submit} htmlType="submit">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                footer={false}
                destroyOnClose={true}
                title="Editar usuário"
                visible={isModalUpdate}
                onCancel={() => setIsModalUpdate(false)}
                onFinish={() => setIsModalUpdate(false)}
            >
                <Form
                    form={form2}
                    layout={"vertical"}
                    onFinish={onFinishUpdate}
                >
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="Nome Completo"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Nome obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "E-mail não é válido!",
                                    },
                                    {
                                        required: true,
                                        message: "Campo senha obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Cargo"
                                name="funcao"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Status" name="status">
                                <Select
                                    style={{ width: 120 }}
                                    defaultValue={statusSelect}
                                >
                                    <Option value={1}>Ativo</Option>
                                    <Option value={0}>Inativo</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="senha"
                                name="senha"
                                rules={[
                                    {
                                        message: "Campo Senha obrigatório!",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Repita a senha"
                                name="confirm"
                                dependencies={["senha"]}
                                hasFeedback
                                rules={[
                                    {
                                        message:
                                            "Please confirm your password!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue("senha") === value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "As duas senhas que você digitou não coincidem!!"
                                                )
                                            )
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="id" hidden={true}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="repeat">
                                <Button loading={submit} htmlType="submit">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Template>
    )
}
export default Usuarios
