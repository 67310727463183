import React from "react"
import Router from "./routes"
import './App.less';
function App() {
    return (
        <>
            <Router />
        </>
    )
}

export default App
