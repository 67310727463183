import React, { useState, useEffect } from "react"
import { Form, InputNumber } from "antd"
import { maskCurrency } from "../utils"
const FIELD_NAME = "value"
const locale = "en-us"
const currencyFormatter = (selectedCurrOpt) => (value) => {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: "EUR",
    }).format(value)
}

const currencyParser = (val) => {
    try {
        // for when the input gets clears
        if (typeof val === "string" && !val.length) {
            val = "0.0"
        }

        // detecting and parsing between comma and dot
        var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "")
        var decimal = new Intl.NumberFormat(locale)
            .format(1.1)
            .replace(/1/g, "")
        var reversedVal = val.replace(new RegExp("\\" + group, "g"), "")
        reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".")
        //  => 1232.21 €

        // removing everything except the digits and dot
        reversedVal = reversedVal.replace(/[^0-9.]/g, "")
        //  => 1232.21

        // // appending digits properly
        const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length
        const needsDigitsAppended = digitsAfterDecimalCount > 2

        if (needsDigitsAppended) {
            reversedVal =
                reversedVal * Math.pow(10, digitsAfterDecimalCount - 2)
        }

        return Number.isNaN(reversedVal) ? 0 : reversedVal
    } catch (error) {
        console.error(error)
    }
}
const InputMoney = (props) => {

    const value = props.value || {}

    const [currency, setCurrency] = React.useState({
        CtryNm: "PORTUGAL",
        CcyNm: "Euro",
        Ccy: "EUR",
        // CcyNbr: "978",
        // CcyMnrUnts: "2",
    })



    return (
        <InputNumber
            formatter={currencyFormatter(currency)}
            style={{ width: "100%" }}
            parser={currencyParser}
            form={props.form}
            value={props.value}
            id={props.id}
            max={2000}
            {...props}
            onChange={(value) => {
                props.form.setFieldsValue({
                    [props.id]: value,
                })
            }}
        />
    )
}

export default InputMoney
