import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Template from "../../components/template"
import { Statistic, Card, Col, Row, Button, DatePicker } from "antd"

import config from "../../config/config"
const Dashboard = () => {
    let history = useHistory()
    const _initToken = localStorage.getItem("authUserOptica")
    const [dash, setDash] = useState({})

    const dataDash = () => {
        fetch(config[process.env.NODE_ENV].url + "/dash", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                console.log(data)
                setDash(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        dataDash()
    }, [])

    return (
        <Template>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {/* {dash.banner != null ? (
                      <img src={config[process.env.NODE_ENV].url + '/banners/' + dash.banner} style={{width: '100%'}} alt="banner publicidade" />
                )  : (
                     <img src="https://via.placeholder.com/1024x180" height="180" width="100%" alt="publicidade" />
                )} */}
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Seguros Vendidos - Semana"
                            value={dash.segurosSemana}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Seguros Vendidos - Acumulado"
                            value={dash.segurosAcumulados}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Total de clientes"
                            value={dash.totalClientes}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Valor a transferir"
                            prefix={"€"}
                            value={0}
                            precision={2}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        {/* <Statistic title="Valor à Transferir" prefix={'€'} value={200} precision={2} /> */}

                        <Button onClick={() => history.push("/novo-seguro")}>
                            Novo Seguro
                        </Button>
                    </Card>
                </Col>
            </Row>
        </Template>
    )
}

export default Dashboard
