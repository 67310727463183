import React from "react"
import { Layout, Menu } from "antd"
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UserOutlined,
    UploadOutlined,
    VideoCameraOutlined,
    ContainerOutlined,
    DatabaseOutlined,
    MailOutlined,
    DashOutlined,
    DashboardOutlined,
} from "@ant-design/icons"
import logo from "../../images/logo.png"
import { Link } from "react-router-dom"
import { useMenu } from "../../provider/menu"

const { Sider } = Layout
const { SubMenu } = Menu
const Siderbar = (props) => {

    const [select, setSelect ] = React.useState("0")

    const {menub, setMenu} = useMenu()


    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={menub.collapsed}
            style={{
                // overflow: "auto",
                height: "100vh",
                // position: "fixed",
                // left: 0,
            }}
        >
           <div className="brand"></div>
            <Menu  mode="inline" selectedKeys={menub.actived} openKeys={menub.submenu}  >
              <Menu.Item key="0" icon={<DashboardOutlined />} onClick={() => setMenu({actived:["0"]})}>
                    <Link to={"/dashboard"}>Dashboard</Link>
                </Menu.Item>
                <Menu.Item key="1" icon={<ContainerOutlined />} onClick={() => setMenu({actived:["1"]})}>
                    <Link to={"/novo-seguro"}>Novo Seguro</Link>
                </Menu.Item>
                <SubMenu
                    key="sub1"
                    icon={<DatabaseOutlined />}
                    title="Gerir"
                >
                    <Menu.Item key="2" onClick={() => setMenu({actived:["2"], submenu: ["sub1"]})}>
                        <Link to={"/gerir"}>Seguros</Link>
                    </Menu.Item>
                    <Menu.Item key="3" onClick={() => setMenu({actived:["3"], submenu: ['sub1']})}>
                        <Link to={"/sinistro"}>Sinistro</Link>
                    </Menu.Item>

                     <Menu.Item key="4" onClick={() => setMenu({actived:["4"], submenu: ['sub1']})}>
                        <Link to={"/clientes"}>Clientes</Link>
                    </Menu.Item>
                    {/* <Menu.Item key="3">option3</Menu.Item>
                    <Menu.Item key="4">option4</Menu.Item> */}
                </SubMenu>

                <Menu.Item key="5" icon={<UserOutlined />} onClick={() => setMenu({actived:["5"]})}>
                    <Link to={"/usuarios"}>Usuários</Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<MailOutlined />} onClick={() => setMenu({actived:["6"]})}>
                    <Link to={"/contato"}>Contato</Link>
                </Menu.Item>
            </Menu>
        </Sider>
    )
}

export default Siderbar
